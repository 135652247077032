'use client';

import { useEffect, useState } from 'react';

import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import { Button } from '@quick/components';

import { ReactComponent as AccessDenied } from '@/assets/access-denied.svg';
import useCompatibility from '@/layers/core/deal/services/useCompatibility';

import { InlineSpinner } from '../InlineSpinner';

const compatibilityAtom = atomWithStorage(
  'isCompatibleV1',
  false,
  createJSONStorage(() => sessionStorage)
);

export function CompatibilityNotice() {
  const { t } = useTranslation('common');

  const [acceptedIncompatibility, setAcceptedIncompatibility] =
    useAtom(compatibilityAtom);

  const [isDisableButton, setIsDisableButton] = useState(true);
  const { browser, isValidBrowser } = useCompatibility();

  useEffect(() => {
    setTimeout(() => setIsDisableButton(false), 5_000);
  }, []);

  if (acceptedIncompatibility || isValidBrowser) return null;

  return (
    <AlertDialog.Root open={!acceptedIncompatibility}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          className="z-100 animate-fadeIn fixed left-0 top-0 flex h-full w-full items-center
            justify-center bg-gray-400/50 p-4 duration-1000 dark:bg-gray-700/50"
        >
          <AlertDialog.Content
            onEscapeKeyDown={(e) => e.preventDefault()}
            className="bg-brand flex w-max max-w-full flex-col items-center justify-center gap-4
              rounded p-4 text-black shadow"
          >
            <AlertDialog.Title className="text-center">
              {t('bowser')}
            </AlertDialog.Title>
            <AlertDialog.Description className="max-w-prose text-pretty text-center">
              {t('incompatibilityMsg', {
                name: browser?.name,
                version: browser?.version,
              })}
            </AlertDialog.Description>
            <AccessDenied className="h-40 w-max" />
            <AlertDialog.Action asChild>
              <Button
                variant="black"
                autoFocus
                onClick={() => setAcceptedIncompatibility(true)}
                disabled={isDisableButton}
              >
                {isDisableButton && <InlineSpinner />}
                {t('understand')}
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Content>
        </AlertDialog.Overlay>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
