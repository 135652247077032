import axios, { AxiosError } from 'axios';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Credentials,
  User,
  credentialsAtom,
  getNowInSeconds,
  inactivitySecondsAtom,
  lastActivityAtom,
  refreshAtom,
  userAtom,
} from './atoms';

const access = selectAtom(credentialsAtom, (s) => s.access);
const refresh = selectAtom(credentialsAtom, (s) => s.refresh);
export const useRefresh = () => useAtomValue(refresh);
export const useAccess = () => useAtomValue(access);

export const useSetCredentials = () => {
  const setCredentials = useSetAtom(credentialsAtom);
  return useCallback(
    (credentials: Pick<Credentials, 'access' | 'refresh'>) => {
      setCredentials({
        access: credentials.access,
        refresh: credentials.refresh,
        lastActivity: getNowInSeconds(),
      });
    },
    [setCredentials]
  );
};
export const useClearCredentials = () => {
  const setCredentials = useSetCredentials();
  return useCallback(
    () =>
      setCredentials({
        access: null,
        refresh: null,
      }),
    [setCredentials]
  );
};
export const useUserDetails = () =>
  useAtomValue(userAtom) ?? ({} as Partial<User>);

function getThrottled<T extends unknown[]>(
  debounce: number,
  callback: (...args: T) => void,
  ...args: T
) {
  let timeout: NodeJS.Timeout | null = null;
  return () => {
    if (!!timeout) return;
    timeout = setTimeout(() => {
      timeout = null;
    }, debounce);
    return callback(...args);
  };
}

export function useInactivity() {
  const [lastActivity, setLastActivity] = useAtom(lastActivityAtom);
  const clearCredentials = useClearCredentials();
  const inactivitySecondsAllowed = useAtomValue(inactivitySecondsAtom);

  const trottledEventHandler = useMemo(
    () => getThrottled(5_000, setLastActivity, []),
    [setLastActivity]
  );
  useEffect(() => {
    window.addEventListener('click', trottledEventHandler);
    window.addEventListener('mousemove', trottledEventHandler);
    window.addEventListener('scroll', trottledEventHandler);
    window.addEventListener('keydown', trottledEventHandler);
    return () => {
      window.removeEventListener('click', trottledEventHandler);
      window.removeEventListener('mousemove', trottledEventHandler);
      window.removeEventListener('scroll', trottledEventHandler);
      window.removeEventListener('keydown', trottledEventHandler);
    };
  }, [trottledEventHandler]);

  /** Forced logout after inactivity time surpasses the allowed inactivity time */
  useEffect(() => {
    const now = getNowInSeconds();
    const timeUntilForcedLogout = lastActivity - now + inactivitySecondsAllowed;

    const debug = typeof window !== 'undefined' && window?.__Q_DI;
    if (debug) {
      console.log({
        timeUntilForcedLogout,
        lastActivity: new Date(lastActivity * 1000),
        now: new Date(now * 1000),
      });
    }

    const timeout = setTimeout(() => {
      clearCredentials();
    }, timeUntilForcedLogout * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [lastActivity]);
}

/**
 * Refreshes the access token a minute before it expires using the refresh token.
 *
 * If the refresh token expired, clear store.
 */
export function useRefreshAccessToken(url: string) {
  const [credentials, setCredentials] = useAtom(credentialsAtom);
  const refresh = useAtomValue(refreshAtom);
  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    if (!refresh) return;
    if (!user) return;
    // Now is after expiry
    const refreshTokenExpired = getNowInSeconds() > refresh.exp;
    if (refreshTokenExpired)
      return setCredentials({
        access: null,
        refresh: null,
        lastActivity: getNowInSeconds(),
      });
    const controller = new AbortController();
    /**
     * Refreshes the access token by sending a POST request to the specified URL with the refresh token.
     * If the request is successful, the access token is updated with the response data.
     * If the request fails with a 401 or 403 status code, the user credentials are reset and the function stops retrying.
     */
    const refreshAccessToken = async () => {
      // Retry until successful or aborted
      while (!controller.signal.aborted) {
        const debug = typeof window !== 'undefined' && window?.__Q_DI;
        if (debug) console.log('Attempting to refresh access token');
        try {
          const response = await axios.post<{ access_token: string }>(
            url,
            { refresh_token: credentials.refresh },
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
              signal: controller.signal,
            }
          );
          setUser(response.data.access_token);
          if (debug) console.log('Refreshed access token', response.data);
          // Get out of retry loop
          break;
        } catch (error) {
          if (!(error instanceof AxiosError)) return;
          const errorCode = error.response?.status ?? 0;
          if (errorCode >= 400 && errorCode < 500) {
            setCredentials({
              access: null,
              refresh: null,
              lastActivity: getNowInSeconds(),
            });
            // Don't retry if refresh token expired
            if (debug) console.log('Refresh token expired', error);
            break;
          }
          await new Promise((r) => setTimeout(r, 1000));
        }
      }
    };
    // Refresh 30 seconds before expiry
    const secondsToRefresh = user.exp - getNowInSeconds() - 30;
    const timeout = setTimeout(
      refreshAccessToken,
      Math.max(secondsToRefresh * 1000, 0)
    );
    return () => {
      clearTimeout(timeout);
      controller.abort();
    };
  }, [url, user?.exp]);
}
