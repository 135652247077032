import '@quick/components/components/flatPicker/FlatPicker.css';
import '@quick/components/components/map/dumbs/styles.css';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import '../styles/globals.css';

import { ComponentType, Fragment, PropsWithChildren } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextComponentType, NextPageContext } from 'next/types';

import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';
import { useReducedMotion } from '@react-spring/web';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'jotai';
import { SSRConfig, appWithTranslation } from 'next-i18next';
import { SWRConfig } from 'swr';

import { Toaster } from '@quick/components';

import {
  Boundary,
  Clarity,
  CookieNotice,
  CompatibilityNotice,
  DetectDVH,
  FontConfig,
  HealthCheckAlert,
  LangProvider,
  ThemeDetector,
  useValidateToken,
} from '@/components/core/AppLayout';
import { EnvTag } from '@/components/core/AppLayout/EnvTag';
import VerifyRequiresNewPass from '@/components/core/AppLayout/VerifyRequiresNewPass';
import { NoSSR } from '@/components/core/NoSSR';
import {
  CountryProvider,
  useCountry,
} from '@/layers/core/deal/services/CountryContext';

/// <reference types="dayjs/plugin/utc" />
/// <reference types="dayjs/plugin/timezone" />

dayjs.extend(utc);
dayjs.extend(timezone);
type NextPageWithLayout = NextComponentType<
  NextPageContext,
  unknown,
  SSRConfig
> & {
  Layout?: ComponentType<PropsWithChildren>;
};

type AppPropsWithLayout = AppProps<SSRConfig> & {
  Component: NextPageWithLayout;
};

const HooksCaller = () => {
  useValidateToken();
  return null;
};

function QuickApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const country = useCountry();
  const lang = (router.query.lang as string) || country.lang;

  const AppLayout = Component?.Layout ?? Fragment;

  return (
    <LangProvider value={lang}>
      <HooksCaller />
      <DetectDVH />
      <FontConfig />
      <ThemeDetector />
      <EnvTag />
      <Clarity />
      <VerifyRequiresNewPass />
      <Boundary>
        <HealthCheckAlert />
        <AppLayout>
          <Head>
            <title key="title">Quick App</title>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <TooltipProvider>
            <Component {...pageProps} />
          </TooltipProvider>
        </AppLayout>
        <NoSSR>
          <CookieNotice />
          <CompatibilityNotice />
        </NoSSR>
      </Boundary>
    </LangProvider>
  );
}

function GlobalApp(props: AppPropsWithLayout) {
  useReducedMotion();
  return (
    <Provider>
      <SWRConfig value={{ use: [] }}>
        <CountryProvider>
          <QuickApp {...props} />
          <Toaster />
        </CountryProvider>
      </SWRConfig>
    </Provider>
  );
}

const app: ComponentType<AppPropsWithLayout> = appWithTranslation(GlobalApp);
export default app;
