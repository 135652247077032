import { AlertVariants } from '../data';

import { ReactComponent as Danger } from '../../../img/danger.svg';
import { ReactComponent as Info } from '../../../img/info.svg';
import { ReactComponent as Success } from '../../../img/success.svg';
import { ReactComponent as Warning } from '../../../img/warning.svg';

/* Select component for DEFAULT key */
const alertTypes = {
  [AlertVariants.SUCCESS]: Success,
  [AlertVariants.DANGER]: Danger,
  [AlertVariants.INFO]: Info,
  [AlertVariants.WARNING]: Warning,
  [AlertVariants.DEFAULT]: Success,
};

export { alertTypes };
