import { useMemo } from 'react';

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Fetcher, SWRConfiguration, default as useSWR } from 'swr';
import { stableHash } from 'swr/_internal';

import { useLangProvider } from '@/components/core/AppLayout';
import { axios_demand } from '@/layers/demand/data/fetchers/instances';

import { useUser } from './useUser';

function compare(a?: AxiosResponse, b?: AxiosResponse) {
  return stableHash(a?.data) === stableHash(b?.data);
}

export interface FetcherKey {
  url: string;
  params?: Record<
    string,
    string | number | boolean | undefined | null | number[] | string[]
  >;
  headers?: AxiosRequestConfig['headers'];
  method?: 'GET' | 'POST' | 'HEAD';
  data?: unknown;
  /** User ID */
  id?: number;
}

type NullableKey =
  | (FetcherKey & {
      auth?: boolean;
      allowNoAuth?: boolean;
      immutable?: boolean;
    })
  | false
  | null
  | undefined
  | '';

function constructKey({
  key,
  token,
  id,
  lang,
}: {
  key: NullableKey;
  token?: string | null;
  id?: number;
  lang: string;
}): NullableKey {
  if (!key) return null;
  const { auth, allowNoAuth, headers, immutable, data, ...k } = key;
  if (auth && !token && !allowNoAuth) return null;
  return {
    ...k,
    data: data ?? {},
    headers: {
      'Accept-Language': lang,
      ...headers,
    },
    id: auth ? id : undefined,
  };
}

function getFetcher<T = unknown>({
  auth,
  token,
}: {
  auth: boolean;
  token?: string;
}) {
  return async ({ url, params, headers, method, data }: FetcherKey) => {
    return await axios_demand<T>({
      method,
      url,
      params,
      headers: {
        ...headers,
        ...(auth && token && { Authorization: token }),
      },
      data,
    });
  };
}

const immutableOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

type FetchOptions<TData = unknown, TError = unknown> =
  | SWRConfiguration<
      AxiosResponse<TData>,
      AxiosError<TError>,
      Fetcher<AxiosResponse<TData>, NullableKey>
    >
  | undefined;

function useFetch<TData = unknown, TError = unknown>(
  key: NullableKey,
  options?: FetchOptions<TData, TError>
) {
  const { token, id } = useUser();
  let auth = false;
  if (key) auth = !!key?.auth;
  const lang = useLangProvider();

  const isImmutable = Boolean(key && key.immutable);

  const k = useMemo(
    () => constructKey({ key, token, lang, id }),
    [key, token, lang, id]
  );

  const fetcher = useMemo(
    () => getFetcher<TData>({ auth, token }),
    [auth, token]
  );

  const swr = useSWR<AxiosResponse<TData>, AxiosError<TError>, NullableKey>(
    k,
    fetcher as Fetcher<AxiosResponse<TData>, NullableKey>,
    {
      compare,
      ...(isImmutable && immutableOptions),
      ...options,
    }
  );

  return new Proxy(swr, {
    get(target, prop) {
      if (prop === 'data') {
        return target.data?.data;
      }
      if (prop === 'raw') {
        return target.data;
      }
      return target[prop as keyof typeof target];
    },
  }) as Omit<typeof swr, 'data'> & {
    data: TData | undefined;
    raw: (typeof swr)['data'];
  };
}

export { useFetch };
